import { getDefaultOptions } from 'API/auth'
import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
import { LinkType } from '../pages/course/[id]/activities/external-video/[external_id]'

export async function getExternalLink(external_id: string, viewAs: string) {
	return await handleApi<LinkType>(
		axios.get(getUrl(`asset/external?external_id=${external_id}&asTeacher=${viewAs === 'Teacher'}`), getDefaultOptions())
	)
}
