import { navigate } from 'gatsby'
import React from 'react'
import { Modal, Col } from 'react-bootstrap'
import { FaPlus } from 'react-icons/fa'
export const CustomModal: React.FC<{ button_action?: string; header: string; onClick?: CallableFunction; disabled_back?: boolean }> = (
	{ children, header, button_action, onClick, disabled_back } = { header: '', disabled_back: false }
) => {
	return (
		<div className='container mt-2 mb-2' style={{ height: '100%' }}>
			<div className='row'>
				<div className='modal-Container col-9 ml-lg-auto mb-2'>
					<Modal show={true} className='modalclass'>
						<Modal.Header className='align-items-center'>
							<Col xs={2} lg={1}>
								<button
									type='button'
									className='close float-left'
									data-dismiss='modal'
									aria-label='Close'
									onClick={() => {
										if (!disabled_back) {
											navigate('../..')
										}
									}}
								>
									<span aria-hidden='true'>&times;</span>
								</button>
							</Col>
							<Col xs={5} lg={8}>
								<h5 className='modal-title float-left'>{header}</h5>
							</Col>
							<Col xs={5} lg={3}>
								<button
									onClick={async (e) => {
										e.preventDefault()
										if (onClick) {
											onClick()
										}
									}}
									className='btn btnAssign btn-primary px-3 px-lg-5'
								>
									<FaPlus className='fa fa-plus mb-1' />
									{/* <i className='fa fa-plus'></i> */}
									<span className='pl-2 h5'>{button_action ?? header}</span>
								</button>
							</Col>
						</Modal.Header>
						<Modal.Body>{children}</Modal.Body>
					</Modal>
				</div>
			</div>
		</div>
	)
}
