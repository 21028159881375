import React from 'react'
import { WindowLocation } from '@reach/router'

export const useEditableHook = ({ location }: { location: WindowLocation<unknown> }) => {
	const [state, setState] = React.useState<{ edit?: 'true'; elem_id: string }>({
		elem_id: '',
	})

	React.useEffect(() => {
		const params = new URLSearchParams(location.search)

		const edit: 'true' | null = params.get('edit') as any
		const elem_id = params.get('elem_id')
		if (edit && elem_id)
			setState({
				edit,
				elem_id,
			})
	}, [location])

	return { ...state }
}
