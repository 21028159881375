import { Switch } from '@material-ui/core'
import { getDefaultOptions } from 'API/auth'

import { getExternalLink } from 'API/getExternalLink'
import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
import { Form } from 'components/Form/Form'
import { navigate, PageProps } from 'gatsby'
import { useEditableHook } from 'hooks/useEditableHook'
import { useGetCourseDetails } from 'hooks/useGetCourseDetails'
import { useGetModulesHook } from 'hooks/useGetModulesHook'
import React from 'react'
import { toast } from 'react-toastify'
import { CustomModal } from '../../../../../components/common/useModal'
const resourceDates = [
	{ text: 'Visible From:', name: 'visible_from' },
	{ text: 'Start Time:', name: 'start' },
	{ text: 'End Time', name: 'end' },
]

const External: React.FC<PageProps> = ({ params: { id: course }, location }) => {
	let ref = React.useRef<HTMLButtonElement | null>(null)

	const [createLink, setCreateLink] = React.useState(false)

	const { type_of_course, stable } = useGetCourseDetails(course)
	const { modules } = useGetModulesHook({ course, stable, type_of_course })

	const { edit, elem_id } = useEditableHook({ location })
	const [editable, setEditable] = React.useState<any>({})
	React.useEffect(() => {
		if (edit === 'true' && elem_id !== '') {
			const run = async () => {
				const asset = await getExternalLink(elem_id, 'Teacher')
				if (asset) {
					console.log('asset', asset)
					setEditable(asset)
				}
			}
			run()
		}
	}, [edit, elem_id])
	return (
		<>
			{!createLink ? (
				<CustomModal
					header='Add External Video Link'
					onClick={() => {
						if (edit === 'true') {
							if (ref.current) {
								ref.current.click()
							}
						}
						console.log(ref)
						if (ref.current) {
							ref.current.click()
						}
					}}
				>
					{/* Use Webex?
					<Switch
						onChange={(e: any) => {
							setCreateLink(true)
						}}
						checked={createLink || false}
						color='default'
						inputProps={{ 'aria-label': 'checkbox with default color' }}
					/> */}
					<>
						<Form
							editableState={editable}
							getRef={(_ref) => {
								if (_ref) ref = _ref
							}}
							action={async ({ state }) => {
								if (edit === 'true') {
									if (edit === 'true') {
										const external = await handleApi<{ uid: string }>(
											axios.patch(
												getUrl('asset/external'),
												{
													...state,
													course,
													video: true,
												},
												getDefaultOptions()
											)
										)
										if (external) navigate(`/course/${course}/activities/external-video/${external.uid}`)
									}
								}
								const external = await handleApi<{ uid: string }>(
									axios.post(
										getUrl('asset/external'),
										{
											...state,
											course,
											video: true,
										},
										getDefaultOptions()
									)
								)
								if (external) navigate(`/course/${course}/activities/external-video/${external.uid}`)
							}}
							text={'Add External Video'}
							fields={[
								{
									type: 'text',
									label: 'Name',
									name: 'name',
								},
								{
									type: 'textarea',
									label: 'Description',
									name: 'description',
								},
								{ label: 'Link', name: 'link', type: 'url' },
								...resourceDates.map((elem) => ({
									type: 'datetime-local',
									label: elem.text,
									name: elem.name,
								})),
								modules.length > 0
									? {
											type: 'select',
											selection: modules,
											name: 'module',
											label: 'Module',
									  }
									: {
											type: 'select',
											selection: [],
											label: '',
											name: '',
									  },
							]}
						></Form>
					</>
				</CustomModal>
			) : (
				<>
					<CustomModal
						header='Add Webex Video Link'
						onClick={() => {
							if (edit === 'true') {
								toast('ToDo', { type: 'info' })
								return
							}
							console.log(ref)
							if (ref.current) {
								ref.current.click()
							}
						}}
					>
						Use External?
						<Switch
							onChange={(e: any) => {
								setCreateLink(false)
							}}
							checked={createLink}
							color='default'
							inputProps={{ 'aria-label': 'checkbox with default color' }}
						/>
						<Form
							editableState={editable}
							getRef={(_ref) => {
								if (_ref) ref = _ref
							}}
							action={async ({ state }) => {
								if (edit === 'true') {
									if (edit === 'true') {
										// toast('ToDo', { type: 'info' })
										return
									}
								}
								console.log({ state })
								const createdMeeting = await handleApi<{ webLink: string }>(
									axios.post(
										getUrl('asset/webex/create_meeting'),
										{
											...state,
											course,
										},
										getDefaultOptions()
									)
								)
								console.log({ createdMeeting })
								if (createdMeeting) {
									const external = await handleApi<{ uid: string }>(
										axios.post(
											getUrl('asset/external'),
											{
												...state,
												link: createdMeeting.webLink,
												course,
												video: true,
											},
											getDefaultOptions()
										)
									)
									if (external) navigate(`/course/${course}/activities/external-video/${external.uid}`)
								}
							}}
							text={'Add External Video'}
							fields={[
								{
									type: 'text',
									label: 'Name',
									name: 'name',
								},
								{
									type: 'textarea',
									label: 'Description',
									name: 'description',
								},
								{ label: 'Host Email', name: 'email', type: 'email' },
								...resourceDates.map((elem) => ({
									type: 'datetime-local',
									label: elem.text,
									name: elem.name,
								})),
								modules.length > 0
									? {
											type: 'select',
											selection: modules,
											name: 'module',
											label: 'Module',
									  }
									: {
											type: 'select',
											selection: [],
											label: '',
											name: '',
									  },
							]}
						></Form>
					</CustomModal>
				</>
			)}
		</>
	)
}

export default External
