import React from 'react'
import { getModules } from 'API/course'
import { NonUndefined, PromiseType, ValuesType } from 'utility-types'

export const useGetModulesHook = ({
	course,
	stable,
	type_of_course,
}: {
	course: string
	stable: boolean
	type_of_course: 'Traditional' | 'Online'
}) => {
	const [modules, setModules] = React.useState<
		Array<ValuesType<NonUndefined<PromiseType<ReturnType<typeof getModules>>>> & { label: string; value: string }>
	>([])

	const run = async () => {
		const modules = await getModules(course)
		let moduleData
		if (modules) {
			moduleData = modules
				.sort((a, b) => a.index - b.index)
				.map((elem) => ({
					...elem,
					label: `${elem.index}. ${elem.name}`,
					value: elem.uid,
				}))
			setModules(moduleData)
		}
		return moduleData
	}

	const runner = React.useCallback(() => {
		if (stable && type_of_course === 'Online' && course) {
			run()
		}
	}, [type_of_course, stable])

	React.useEffect(() => {
		runner()
	}, [type_of_course, stable])

	return { modules, runner, run }
}
